import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { WorkOrder } from '../models/work-order.model';
import { BaseClientService } from './base-client.service';
import { urlType } from '../models/urlType';
import { UpdateKeys } from '../models/updateKeys.model';

@Injectable({
  providedIn: 'root'
})
export class HotelLeadsFormService {

  constructor(private http: HttpClient, private readonly cookieService: CookieService,
     private readonly baseClientService: BaseClientService) { }

  // ngOnInit() {

  // }

  fetchCustomerData(fileId) {
    return this.baseClientService.get(`/vendor/customerFilesById?fileId=${fileId}`, '', urlType.processAPI).pipe(
      map(response => response.body)
    );
  }

  sendFormData(formData) {
    return this.baseClientService.post('/vendor/lead', formData, '', urlType.processAPI).pipe(
        map(response => response.body)
      );
  }

  getLeadByOrderId(orderId) {
    return this.baseClientService.get(`/vendor/leadByOrderId?orderId=${orderId}`, '', urlType.processAPI).pipe(
      map(response => response.body)
    );
  }

  getLeadsListData(params?) {
    return this.baseClientService.get(`/vendor/leads${params}`, '', urlType.processAPI).pipe(
      map(response => response)
    );
  }
}

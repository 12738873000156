import { Component, OnInit, Input, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { HotelLeadsFormService } from '../../../services/hotel-leads-form.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationsService } from '../../../../core/services/notifications.service';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-hotel-leads-form',
  templateUrl: './hotel-leads-form.component.html',
  styleUrls: ['./hotel-leads-form.component.scss']
})
export class HotelLeadsFormComponent implements OnInit {

  hotelForm: UntypedFormGroup;
  petTypes: any = [
    {name: 'bird', value: 'Bird'},
    {name: 'cat', value: 'Cat'},
    {name: 'dog', value: 'Dog'},
    {name: 'other', value: 'Other'},
    {name: 'snake', value: 'Snake'}
  ];

  petAccommodationPayTypes: any = [
    {name: 'cartus', value: 'Paid by Cartus'},
    {name: 'customer', value: 'Paid by Customer'},
  ];

  ContactTransfereeTypes = [
    'Cartus Consultant',
    'Customer'
  ];

  billToFieldValues = [
    {name: 'cartus', value: 'Cartus'},
    {name: 'customer', value: 'Customer'}
  ];

  currencyTypes = [
    { 'currencyAbbr' : 'USD', 'currencyName' : 'US Dollar' },
    { 'currencyAbbr' : 'AUD', 'currencyName' : 'Australian Dollar' },
    { 'currencyAbbr' : 'BRL', 'currencyName' : 'Brazilian Real' },
    { 'currencyAbbr' : 'CAD', 'currencyName' : 'Canadian Dollar' },
    { 'currencyAbbr' : 'CHF', 'currencyName' : 'Swiss Franc' },
    { 'currencyAbbr' : 'CNY', 'currencyName' : 'Chinese Yuan Renminbi' },
    { 'currencyAbbr' : 'CZK', 'currencyName' : 'Czech Koruna' },
    { 'currencyAbbr' : 'DKK', 'currencyName' : 'Danish Krone' },
    { 'currencyAbbr' : 'EUR', 'currencyName' : 'Euro' },
    { 'currencyAbbr' : 'GBP', 'currencyName' : 'British Pound' },
    { 'currencyAbbr' : 'HKD', 'currencyName' : 'Hong Kong Dollar' },
    { 'currencyAbbr' : 'INR', 'currencyName' : 'Indian Rupee' },
    { 'currencyAbbr' : 'JPY', 'currencyName' : 'Japanese Yen' },
    { 'currencyAbbr' : 'NOK', 'currencyName' : 'Norwegian Krone' },
    { 'currencyAbbr' : 'PLN', 'currencyName' : 'Polish Zloty' },
    { 'currencyAbbr' : 'SEK', 'currencyName' : 'Swedish Krona' },
    { 'currencyAbbr' : 'SGD', 'currencyName' : 'Singapore Dollar' },
    { 'currencyAbbr' : 'ZAR', 'currencyName' : 'South African Rand' }
  ];

  geographicOriginValues = [
    // 'Brazil',
    // 'Canada',
    // 'China',
    'France',
    'Germany',
    'Hong Kong',
    // 'India',
    'Netherlands',
    'Singapore',
    'Switzerland',
    'United Kingdom',
    'United States'
  ];

  isPetsSelected = false;
  isParkingSelected = false;
  isDirectedInfoDisplay = false;
  isDogSelected = false;
  disableNewLead = true;
  isDialogOpned = false;
  announceStmt;
  defaultValues;

  constructor(private fb: UntypedFormBuilder,
    private hotelLeadsFormService: HotelLeadsFormService,
    public spinner: NgxSpinnerService,
    private readonly notificationsService: NotificationsService,
    public dialogRef: MatDialogRef<HotelLeadsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: {leadData: Object}) {

  }

  ngOnInit() {
    this.createForm();
    this.isDialogOpned = false;
    if (this.dialogData && this.dialogData.leadData && Object.keys(this.dialogData.leadData).length) {
      this.isDialogOpned = true;
      this.hotelForm.patchValue(this.dialogData.leadData);
      this.custRadioBtnChange();
      this.petTypeChange();
      this.accmdtRadioBtnChange();
      this.billToRadioBtnChange();
      this.hotelForm.disable();
      document.getElementById('mainSection').style.boxShadow = 'none';
    } else {
      document.getElementById('mainSection').style.maxHeight = 'none';
      document.getElementById('mainSection').style.margin = '0';
    }

  }

  onCloseClick() {
    this.dialogRef.close();
  }

  createForm () {
    this.hotelForm = this.fb.group({
      customerProfileInfo: this.fb.group({
        customerName: ['', Validators.required],
        customerEmail: ['', Validators.compose([Validators.required, Validators.email])],
        customerFileId: ['', Validators.required],
        bestTimeToCall: [''],
        premierExecutive: [false],
        geographicOrigin: ['', Validators.required],
        contactNumber: this.fb.group({
          homeNum: [''],
          cellNum: [''],
          workNum: ['']
        }, { validators: this.atLeastOnePhoneRequired}),
        departureLocationDetails: ['', Validators.required],
        numberOfGuests: ['', Validators.required],
        pets: ['', Validators.required],
        weightOfPet: [''],
        petType: [''],
        petBreed: [''],
        petAccommodationPaidBy: [''],
        roomType: ['', Validators.required],
        parking: ['', Validators.required],
        parkingPaidBy: [''],
        consultantName: ['', Validators.required],
        consultantPhone: ['', Validators.required],
        consultantEmail: ['', Validators.compose([Validators.required, Validators.email])],
        whoToContact: ['Cartus Consultant', Validators.required],
        encryptedData: ['']
      }),
      clientProfileInfo: this.fb.group({
        clientName: ['', Validators.required],
        clientNumber: ['', Validators.required],
        billTo: ['', Validators.required],
        ClientBillingAddr1: [''],
        ClientBillingAddr2: [''],
        NightCoverdPolicy: ['', Validators.required],
        budget: ['', Validators.required],
        currency: ['', Validators.required]
      }),
      destinationDetailsInfo: this.fb.group({
        newWorkAddrs: ['', Validators.required],
        destinationCity: ['', Validators.required],
        lengthOfStay: ['', Validators.required],
        checkInDate: ['', Validators.required],
        splInstruction: ['']
      }),
      accommodationCatInfo: this.fb.group({
        bookingType: ['', Validators.required],
        bookingInfo: this.fb.group({
          name: [''],
          specialInstructions: ['']
        })
      })
    });
    this.defaultValues = this.hotelForm.value;
  }
  atLeastOnePhoneRequired(group: UntypedFormGroup): {[s: string]: boolean} {
    if (group) {
      if (group.controls['homeNum'].value || group.controls['cellNum'].value || group.controls['workNum'].value) {
        return null;
      }
    }
    return {'error': true};
  }

  custRadioBtnChange() {
    this.isPetsSelected = false;
    this.isParkingSelected = false;
    if (this.hotelForm.value.customerProfileInfo.pets === 'yes') {
      this.isPetsSelected = true;
      this.hotelForm.controls.customerProfileInfo.get('weightOfPet').setValidators([Validators.required]);
      this.hotelForm.controls.customerProfileInfo.get('petType').setValidators([Validators.required]);
      this.hotelForm.controls.customerProfileInfo.get('petAccommodationPaidBy').setValidators([Validators.required]);
    }
    if (this.hotelForm.value.customerProfileInfo.parking === 'yes') {
      this.isParkingSelected = true;
      this.hotelForm.controls.customerProfileInfo.get('parkingPaidBy').setValidators([Validators.required]);
    }
    if (!this.isPetsSelected) {
      this.hotelForm.controls.customerProfileInfo.get('weightOfPet').patchValue('');
      this.hotelForm.controls.customerProfileInfo.get('petType').patchValue('');
      this.hotelForm.controls.customerProfileInfo.get('petAccommodationPaidBy').patchValue('');
      this.hotelForm.controls.customerProfileInfo.get('weightOfPet').clearValidators();
      this.hotelForm.controls.customerProfileInfo.get('petType').clearValidators();
      this.hotelForm.controls.customerProfileInfo.get('petAccommodationPaidBy').clearValidators();
      this.hotelForm.controls.customerProfileInfo.get('weightOfPet').updateValueAndValidity();
      this.hotelForm.controls.customerProfileInfo.get('petType').updateValueAndValidity();
      this.hotelForm.controls.customerProfileInfo.get('petAccommodationPaidBy').updateValueAndValidity();
    }
    if (!this.isParkingSelected) {
      this.hotelForm.controls.customerProfileInfo.get('parkingPaidBy').patchValue('');
      this.hotelForm.controls.customerProfileInfo.get('parkingPaidBy').clearValidators();
      this.hotelForm.controls.customerProfileInfo.get('parkingPaidBy').updateValueAndValidity();
    }
  }

  petTypeChange() {
    this.isDogSelected = false;
    if (this.hotelForm.value.customerProfileInfo.petType === 'dog') {
      this.isDogSelected = true;
      this.hotelForm.controls.customerProfileInfo.get('petBreed').setValidators([Validators.required]);
    } else {
      this.hotelForm.controls.customerProfileInfo.get('petBreed').patchValue('');
      this.hotelForm.controls.customerProfileInfo.get('petBreed').clearValidators();
      this.hotelForm.controls.customerProfileInfo.get('petBreed').updateValueAndValidity();
    }
  }

  accmdtRadioBtnChange() {
    this.isDirectedInfoDisplay = false;
    if (this.hotelForm.value.accommodationCatInfo.bookingType === 'clientDirected') {
      this.isDirectedInfoDisplay = true;
      this.hotelForm.controls.accommodationCatInfo.get('bookingInfo').get('name').setValidators([Validators.required]);
    }
    if (!this.isDirectedInfoDisplay) {
      this.hotelForm.controls.accommodationCatInfo.get('bookingInfo').setValue({
        name: '', specialInstructions: ''
      });
      this.hotelForm.controls.accommodationCatInfo.get('bookingInfo').get('name').clearValidators();
    }
    this.hotelForm.controls.accommodationCatInfo.get('bookingInfo').get('name').updateValueAndValidity();


  }

  billToRadioBtnChange () {
    if (this.hotelForm.value.clientProfileInfo.billTo === 'client') {
      this.hotelForm.controls.clientProfileInfo.get('ClientBillingAddr1').setValidators([Validators.required]);
      this.hotelForm.controls.clientProfileInfo.get('ClientBillingAddr2').setValidators([Validators.required]);
    } else {
      this.hotelForm.controls.clientProfileInfo.get('ClientBillingAddr1').clearValidators();
      this.hotelForm.controls.clientProfileInfo.get('ClientBillingAddr2').clearValidators();
    }
    this.hotelForm.controls.clientProfileInfo.get('ClientBillingAddr1').updateValueAndValidity();
    this.hotelForm.controls.clientProfileInfo.get('ClientBillingAddr2').updateValueAndValidity();
  }

  onKeydownMain(event): void {
    if (event.key === '-' ) {
      event.preventDefault();
    }
  }

  fetchCustomerData() {
    const fileId = this.hotelForm.controls.customerProfileInfo.get('customerFileId').value;
    let customerdata = {
      customerProfileInfo: {
        customerName: '',
        contactNumber: {
          homeNum: '',
          cellNum: '',
          workNum: ''
        },
        customerEmail: '',
        departureLocationDetails: '',
        encryptedData: ''
      },
      clientProfileInfo: {
        clientName: '',
        clientNumber: '',
      }
    };
    if (fileId) {
      this.spinner.show();
      this.hotelForm.controls.customerProfileInfo.get('customerName').enable();
      this.hotelForm.controls.clientProfileInfo.get('clientName').enable();
      this.hotelForm.controls.clientProfileInfo.get('clientNumber').enable();
      this.hotelLeadsFormService.fetchCustomerData(fileId).subscribe({
        next: data => {
          this.spinner.hide();
          const result = (data && Object.keys(data).length) ? data : null;
          if (result) {
            customerdata = {
              customerProfileInfo: {
                contactNumber: {
                  homeNum: result['cusPhone'],
                  cellNum: result['cusPersonal'],
                  workNum: result['cusWork']
                },
                customerName: result['customerNames'],
                customerEmail: result['cusEmail'],
                departureLocationDetails: !(result['cusAddress'] && result['cusAddress'][0]) ?
                '' : `${result['cusAddress'][0]['city']} ${(result['cusAddress'][0]['state']) ?
                  ', ' + result['cusAddress'][0]['state'] : ''} ${(result['cusAddress'][0]['postalCode']) ?
                  ', ' + result['cusAddress'][0]['postalCode'] : ''}, ${result['cusAddress'][0]['country']}`,
                encryptedData: result['encryptedData']
              },
              clientProfileInfo: {
                clientName: result['clientName'] ,
                clientNumber: result['clientNo']
              }
            };
            this.hotelForm.controls.customerProfileInfo.get('customerFileId').setErrors({invalid: false});
            this.hotelForm.controls.customerProfileInfo.get('customerFileId').updateValueAndValidity();
          } else {
            this.hotelForm.controls.customerProfileInfo.get('customerFileId').setErrors({invalid: true});
            this.enableNotification('error', 'No records match the search criteria entered. Please try again');
          }
          this.hotelForm.patchValue(customerdata);
          this.hotelForm.updateValueAndValidity();
          if (customerdata.customerProfileInfo.customerName) {
            this.hotelForm.controls.customerProfileInfo.get('customerName').disable();
          }
          if (customerdata.clientProfileInfo.clientName) {
            this.hotelForm.controls.clientProfileInfo.get('clientName').disable();
          }
          if (customerdata.clientProfileInfo.clientNumber) {
            this.hotelForm.controls.clientProfileInfo.get('clientNumber').disable();
          }
        },
        error: (error: HttpErrorResponse) => {
          this.spinner.hide();
          this.hotelForm.controls.customerProfileInfo.get('customerFileId').setErrors({invalid: true});
          this.enableNotification('error', 'No records match the search criteria entered. Please try again');
        }
      });
    }
  }

  sendBookingInfo() {
    if (this.hotelForm.valid) {
      this.hotelForm.disable();
      this.spinner.show();
      this.announceStmt = 'Loading, please wait.';
      const formData = this.hotelForm.getRawValue();
      if (formData.customerProfileInfo && formData.customerProfileInfo.contactNumber) {
        for (const propName in formData.customerProfileInfo.contactNumber) {
          if (formData.customerProfileInfo.contactNumber[propName] === null
            || formData.customerProfileInfo.contactNumber[propName] === undefined
            || formData.customerProfileInfo.contactNumber[propName] === '') {
            delete formData.customerProfileInfo.contactNumber[propName];
          }
        }
      }
      this.hotelLeadsFormService.sendFormData(formData).subscribe({
        next: data => {
        this.disableNewLead = false;
        this.spinner.hide();
        this.enableNotification('success', 'The form submission was successful');
        // this.hotelForm.reset();
      },
      error: (error: HttpErrorResponse) => {
        this.hotelForm.enable();
        this.hotelForm.controls.customerProfileInfo.get('customerName').disable();
        this.hotelForm.controls.clientProfileInfo.get('clientName').disable();
        this.hotelForm.controls.clientProfileInfo.get('clientNumber').disable();
        this.spinner.hide();
        this.enableNotification('error', 'Failed to submit the form');
      }});
    } else {
      this.enableNotification('error', 'Please enter all required information');
    }
  }

  createNewLead(ngForm) {
    ngForm.resetForm();
    this.hotelForm.enable();
    this.hotelForm.patchValue(this.defaultValues);
    this.custRadioBtnChange();
    this.petTypeChange();
    this.accmdtRadioBtnChange();
    this.billToRadioBtnChange();
    document.getElementById('fileId').focus();
    this.disableNewLead = true;
  }

  enableNotification(type: string, msg: string) {
    this.notificationsService.flashNotification(type, msg, true, 'dismiss');
  }

}
